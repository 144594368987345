import React from 'react';
import Slider from 'react-slick';
import styled, { keyframes } from 'styled-components';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import slider1 from '../assets/slider1.jpg';
import slider3 from '../assets/slider3.jpg';

const fadeAnimation = keyframes`
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
`;

const StyledSlider = styled(Slider)`
  position: relative;
  margin: 0 auto;
  overflow: hidden;

  .slick-list {
    margin: 0;
    overflow: hidden;
    max-height: 60vh; /* Set height to 60% of the viewport height */
    animation: ${fadeAnimation} 1.5s ease-out;
  }

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-slide div {
    outline: none;
    height: 100%;
    
    img {
      width: 100%;
      max-height: 60vh; /* Set height to 60% of the viewport height */
      object-fit: cover;
      display: block;
      transition: transform 0.5s ease;
    }
  }

  .slick-slide:hover div img {
    transform: scale(1.05);
  }

  .slick-prev, .slick-next {
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.9;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-50%) scale(1.1);
      opacity: 1;
    }
  }

  .slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 40px;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev {
    left: 10px;
  }
  .slick-next {
    right: 10px;
  }

  .slick-dots {
    bottom: 10px;
    li button:before {
      font-size: 12px;
      color: #fff;
    }
  }
`;

// Text fade-in animation
const fadeInText = keyframes`
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const TextOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column; /* Arrange children in a column */
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  padding: 15px;
  animation: ${fadeInText} 1.5s ease-out;
  z-index: 10;

  h2 {
    font-size: 2.5rem; /* Adjusted for viewport height */
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    color: #ffffff;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
    background: rgba(0, 0, 0, 0.5);
    padding: 0.6rem 1.2rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    display: inline-block;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  p {
    font-size: 1.5rem; /* Adjusted for viewport height */
    line-height: 1.5;
    color: #f1f1f1;
    font-family: 'Roboto', sans-serif;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
    background: rgba(0, 0, 0, 0.6);
    padding: 0.4rem 0.8rem;
    border-radius: 6px;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 1.5rem;
    }
    p {
      font-size: 1rem;
    }
  }
`;

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 2500,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 3000,
          arrows: false,
          adaptiveHeight: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          autoplaySpeed: 3500,
        }
      }
    ]
  };

  const slidesData = [
    {
      img: slider1,
      title: "Design",
      description: "Explore innovative designs that push creative boundaries."
    },
    {
      img: slider3,
      title: "Develop",
      description: "Craft cutting-edge solutions that drive global impact."
    },
    {
      img: slider3,
      title: "Deploy",
      description: "Launch your projects with precision and global reach."
    }
  ];

  return (
    <StyledSlider {...settings}>
      {slidesData.map((slide, index) => (
        <div key={index}>
          <img src={slide.img} alt={`Slide ${index}`} />
          
          <TextOverlay>
            <h2>{slide.title}</h2>
            <p>{slide.description}</p>
          </TextOverlay>
        </div>
      ))}
    </StyledSlider>
  );
};

export default Carousel;
