import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FiMenu, FiBriefcase, FiInfo, FiMail, FiHome } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { FaLeaf } from 'react-icons/fa'; // Importing the leaf icon
// Animation keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeInDown = keyframes`
  from {
    transform: translateY(-5px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;
const floatUp = keyframes`
  0%, 100% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: translateY(-10px) rotate(-3deg);
  }
`;
// Styled components
const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;  /* Responsive padding */
  background: linear-gradient(135deg, #1e4d92 0%, #1b2839 100%);
  color: #ffffff;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.6);
  animation: ${fadeIn} 0.7s ease-out forwards;
  position: sticky;  /* Changed from 'relative' to 'sticky' */
  top: 0;  /* Ensures the header sticks to the top of the viewport */
  z-index: 10;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 10%, rgba(0, 0, 0, 0.5) 100%);
    z-index: -1;
  }
  font-family: 'Roboto', sans-serif;
  @media (max-width: 1024px) {
    padding: 10px 15px;
  }
  @media (max-width: 768px) {
    padding: 5px 10px;
  }
`;
const LeafIcon = styled(FaLeaf)`
  transition: transform 0.3s ease-in-out;
  color: #2ecc71;
`;
const Logo = styled.h1`
  font-size: 2rem;
  cursor: pointer;
  animation: ${fadeInDown} 0.7s ease-out forwards;
  transform: rotateY(0deg);
  transition: transform 0.3s;
  &:hover {
    transform: rotateY(20deg);

    /* Target the LeafIcon specifically on hover */
    ${LeafIcon} {
      animation: ${floatUp} 2.5s ease-in-out infinite;
    }
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const EcoPart = styled.span`
  color: #2ecc71; /* Specified green color */
  align-items: center;
  gap: 4px;
`;


const Nav = styled.nav`
  display: flex;
  gap: 20px;
  font-size: 1.1rem;
  transform-style: preserve-3d;
  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileMenuIcon = styled(FiMenu)`
  font-size: 2rem;
  display: none;
  cursor: pointer;
  color: #ffffff;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: #6ec1e4;
  }
  @media (max-width: 768px) {
    display: block;
  }
`;

const NavLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: color 0.3s ease-in-out, transform 0.3s;
  position: relative;
  padding: 5px 0;
  &::after {
    content: '';
    position:absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #6ec1e4;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
  }
  &:hover, &:focus {
    color: #a1dbff;
    &::after {
      transform: scaleX(1);
    }
  }
  &:hover {
    transform: translateZ(15px);
  }
`;

// Responsive Nav component for mobile and tablet
const ResponsiveNav = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: linear-gradient(135deg, #1e4d92 0%, #1b2839 100%);
    top: 100%;
    left: 0;
    right: 0;
    padding: 10px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.5);
    z-index: 9;
  }
`;

// Header component
const Header = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Container>
       <Logo>
        <EcoPart><LeafIcon />Keys</EcoPart>Ltd
      </Logo>
      <Nav>
        <NavLink to="/"><FiHome />Home</NavLink>
        <NavLink to="/about"><FiInfo />About</NavLink>
        <NavLink to="/services"><FiBriefcase />Portfolio</NavLink>
        <NavLink to="/contact"><FiMail />Contact</NavLink>
      </Nav>
      <MobileMenuIcon onClick={() => setIsOpen(!isOpen)} />
      {isOpen && (
        <ResponsiveNav>
          <NavLink to="/" onClick={() => setIsOpen(false)}><FiHome />Home</NavLink>
          <NavLink to="/about" onClick={() => setIsOpen(false)}><FiInfo />About</NavLink>
          <NavLink to="/services" onClick={() => setIsOpen(false)}><FiBriefcase />Portfolio</NavLink>
          <NavLink to="/contact" onClick={() => setIsOpen(false)}><FiMail />Contact</NavLink>
        </ResponsiveNav>
      )}
    </Container>
  );
};

export default Header;
