import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaRegThumbsUp, FaRegStar, FaRegLightbulb } from 'react-icons/fa';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';

const floatIn = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const WhyContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 50px;
  background: radial-gradient(circle, #f8f9fa 0%, #e9ecef 100%);
  border-top: 5px solid #3498db;

  @media (max-width: 768px) {
    padding: 50px 20px;
  }
`;

const Title = styled.h2`
  font-size: 3rem;
  color: #123456;
  margin-bottom: 80px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 40%;
    height: 5px;
    background-color: #0d6efd;
    border-radius: 2.5px;
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-bottom: 40px;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 50px;
  max-width: 1400px;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 30px;
  }
`;

const FeatureCard = styled.div`
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 24px rgba(0,0,0,0.12);
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s ease-in-out;
  animation: ${floatIn} 1s ease-out forwards;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 35px rgba(0,0,0,0.25);
  }
`;

const FeatureIcon = styled.div`
  font-size: 4.5rem;
  color: #0d6efd;
  margin-bottom: 25px;
`;

const FeatureTitle = styled.h3`
  font-size: 2rem;
  color: #343a40;
  margin: 20px 0;
  text-align: center;
`;

const FeatureText = styled.p`
  text-align: center;
  font-size: 1.3rem;
  color: #495057;
  line-height: 1.8;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const LearnMoreLink = styled.a`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #007bff;
  text-decoration: none;
  margin-top: 20px;
  svg {
    font-size: 1.5rem;
    margin-left: 8px;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const WhyChooseUs = () => {
  return (
    <WhyContainer>
      <Title>Why Choose Us</Title>
      <FeaturesGrid>
        <FeatureCard>
          <FeatureIcon><FaRegThumbsUp /></FeatureIcon>
          <FeatureTitle>Trusted Quality</FeatureTitle>
          <FeatureText>
            We deliver exceptional quality products that meet the highest industry standards.
          </FeatureText>
          <LearnMoreLink href="#!">
            Learn more <MdOutlineKeyboardArrowRight />
          </LearnMoreLink>
        </FeatureCard>
        <FeatureCard>
          <FeatureIcon><FaRegStar /></FeatureIcon>
          <FeatureTitle>Exceptional Service</FeatureTitle>
          <FeatureText>
            Our team ensures your satisfaction with top-tier customer service available 24/7.
          </FeatureText>
          <LearnMoreLink href="#!">
            Learn more <MdOutlineKeyboardArrowRight />
          </LearnMoreLink>
        </FeatureCard>
        <FeatureCard>
          <FeatureIcon><FaRegLightbulb /></FeatureIcon>
          <FeatureTitle>Innovative Solutions</FeatureTitle>
          <FeatureText>
            We provide innovative solutions that advance efficiency and effectiveness for our clients.
          </FeatureText>
          <LearnMoreLink href="#!">
            Learn more <MdOutlineKeyboardArrowRight />
          </LearnMoreLink>
        </FeatureCard>
      </FeaturesGrid>
    </WhyContainer>
  );
};

export default WhyChooseUs;
