import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaTree, FaRecycle, FaGlobe } from 'react-icons/fa';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import bimage from "../assets/slider1.jpg";
import video from "../assets/Sequence.mp4";
import profile from "../assets/profile.jpg";

// Keyframes for animations
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(50px); }
  to { opacity: 1; transform: translateY(0); }
`;

const slideIn = keyframes`
  from { opacity: 0; transform: translateX(-100%); }
  to { opacity: 1; transform: translateX(0); }
`;

// Styled components
const HeroSection = styled.div`
  height: 60vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.5);

  video {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  h1, p {
    z-index: 1;
    font-size: 4rem;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
  }

  p {
    margin-top: 10px;
    font-size: 1.5rem;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 3rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`;

const AboutContainer = styled.section`
  padding: 40px;
  animation: ${fadeIn} 1.5s ease-out;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 30px;
  background: ${({ inverse }) => inverse ? '#f4f4f4' : 'white'};
  flex-direction: ${({ inverse }) => inverse ? 'row-reverse' : 'row'};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: box-shadow 0.3s ease;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 40px 20px;
  }
`;

const TextBlock = styled.div`
  flex: 1;
  padding: 20px;

  h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.3rem;
    color: #333;
    line-height: 1.8;
  }

  @media (max-width: 768px) {
    padding: 10px;
    p {
      font-size: 1.1rem;
    }
  }
`;

const ImageBlock = styled.div`
  flex: 1;
  padding: 20px;
  text-align: center;
  animation: ${slideIn} 1s ease-out;
  
  img {
    max-width: 80%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    img {
      max-width: 100%;
    }
  }
`;

const Timeline = styled.div`
  background: #f8f8f8;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const TimelineItem = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 10px;
  border-left: 4px solid #102a43;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Year = styled.span`
  font-size: 1.8rem;
  font-weight: bold;
  margin-right: 10px;
  color: #102a43;
`;

const Testimonials = styled.div`
  padding: 50px;
  background: #e6e6e6;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  margin-bottom: 20px;

  p {
    font-size: 1.2rem;
    color: #333;
    line-height: 1.6;
    text-align: center;
  }

  h3 {
    margin-top: 20px;
    font-size: 1.8rem;
    color: #102a43;
    text-align: center;
  }
`;

const SustainabilitySection = styled(Section)`
  background: #defade;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  h2 {
    color: #2a652d;
    margin-bottom: 20px;
  }

  p {
    color: #285928;
    font-size: 1.2rem;
  }

  div {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  svg {
    font-size: 2.5rem;
    color: #2a652d;
    margin-right: 10px;
  }
`;

const TeamGridContainer = styled.section`
  padding: 30px 30px;
  text-align: center;
  background: linear-gradient(to bottom, #ffffff, #f0f9ff);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
`;

const TeamGridHeader = styled.h2`
  font-size: 3rem;
  color: #102a43;
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: ${fadeIn} 0.8s ease-out;
  
`;

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  animation: ${fadeIn} 1s ease-out;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const TeamMember = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  }

  img {
    width: 100%;
    max-width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid #102a43;
    transition: border 0.3s ease;
  }

  h3, p {
    color: #102a43;
    margin: 10px 0;
  }

  h3 {
    font-size: 1.8rem;
    font-weight: 600;
  }

  p {
    font-size: 1.3rem;
    opacity: 0.7;
  }
`;

const IconTree = styled(FaTree)`
  font-size: 2rem;
  color: green;
`;

const settings = {
  dots: true,
  infinite: true,
  speed: 600,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 6000,
};

// Component
const AboutCompany = () => {
  return (
    <>
      <HeroSection>
      <video src={video} autoPlay muted loop />
        <h1>Welcome to Our Journey</h1>
        <p>Empowering Networking Solutions</p>
      </HeroSection>
      <AboutContainer>
        <Section>
          <TextBlock>
            <h2>Our Story</h2>
            <p>
              Keys Limited is dedicated to delivering innovative networking
              solutions to businesses worldwide. With years of experience in the
              industry, we believe in achieving excellence through customer
              satisfaction and continuous innovation.
            </p>
          </TextBlock>
          <ImageBlock>
            <img src={bimage} alt="Networking solutions" />
          </ImageBlock>
        </Section>
        <Timeline>
          <h2>Milestones</h2>
          <TimelineItem>
            <Year>2015</Year>
            <p>Founded with a vision to provide networking solutions globally.</p>
          </TimelineItem>
          <TimelineItem>
            <Year>2017</Year>
            <p>Launched our flagship software suite, gaining significant traction.</p>
          </TimelineItem>
          <TimelineItem>
            <Year>2020</Year>
            <p>Expanded into new markets, serving clients in multiple continents.</p>
          </TimelineItem>
        </Timeline>
        <Testimonials>
          <Slider {...settings}>
            <div>
              <p>
                "Keys Limited's solutions helped us streamline our networking
                infrastructure with great success!"
              </p>
              <h3>— John Doe, CTO of TechCorp</h3>
            </div>
            <div>
              <p>
                "Their customer service is exceptional. They solved our issues promptly."
              </p>
              <h3>— Jane Smith, IT Manager at GlobalNet</h3>
            </div>
          </Slider>
        </Testimonials>
        <SustainabilitySection>
          <h2>Sustainability Goals</h2>
          <p>Our goal is to build a sustainable future through networking innovations.</p>
          <div>
            <IconTree />
            <span>Tree Planting Programs</span>
          </div>
          <div>
            <FaRecycle />
            <span>Recycling Initiatives</span>
          </div>
          <div>
            <FaGlobe />
            <span>Global Collaboration</span>
          </div>
        </SustainabilitySection>
        <TeamGridContainer>
          <TeamGridHeader>Leadership</TeamGridHeader>
          <TeamGrid>
            <TeamMember>
              <img src={profile} alt="Team member" />
              <h3>Alex Johnson</h3>
              <p>Lead Developer</p>
            </TeamMember>
            <TeamMember>
              <img src={profile} alt="Team member" />
              <h3>Sarah Lopez</h3>
              <p>Marketing Manager</p>
            </TeamMember>
            <TeamMember>
              <img src={profile} alt="Team member" />
              <h3>Michael Brown</h3>
              <p>Head of Operations</p>
            </TeamMember>
          </TeamGrid>
        </TeamGridContainer>
      </AboutContainer>
    </>
  );
};

export default AboutCompany;
