// src/components/HomePage.js
import React from 'react';
import Header from '../components/Header';
import Crousal from '../components/Crousal';
import Footer from '../components/Footer';
import About from '../components/about'
import OurServices from '../components/ourservices';
import WhyChooseUs from '../components/whychooseus';
    const HomePage = () => {
        return (
          <>
            <Header />
            <Crousal />
            <About />
            <OurServices />
            <WhyChooseUs />
            <Footer />
          </>
        );
      };
      

export default HomePage;
