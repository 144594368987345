import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FiPhone, FiMapPin, FiMail, FiBriefcase, FiInfo, FiHome } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { FaLeaf } from 'react-icons/fa'; // Importing the leaf icon
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const floatUp = keyframes`
  0%, 100% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: translateY(-10px) rotate(-3deg);
  }
`;
const FooterContainer = styled.footer`
  background: linear-gradient(135deg, #1e4d92 10%, #1b2839 90%);
  color: #ffffff;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 30px 10px;
  }
`;
const LeafIcon = styled(FaLeaf)`
  color: #2ecc71; // Same color as the text
  transition: transform 0.3s ease-in-out;
  &:hover {
    animation: ${floatUp} 2.5s ease-in-out infinite;
  }
`;

const FooterLogo = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  animation: ${fadeIn} 0.5s ease-out;
 cursor:pointer;
  &:hover {
    transform: rotateY(20deg);

    /* Target the LeafIcon specifically on hover */
    ${LeafIcon} {
      animation: ${floatUp} 2.5s ease-in-out infinite;
    }
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;


const EcoPart = styled.span`
color: #2ecc71; // Same color as the text
  align-items: center;
  gap: 4px;
`;


const FooterInfo = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 40px;
  text-align: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: translateY(-5px);
    color: #2ecc71;
  }
`;

const InfoIcon = styled.span`
  font-size: 2rem;
  margin-bottom: 8px;
`;

const InfoText = styled.span`
  font-size: 1.1rem;
`;

const FooterNav = styled.nav`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  padding: 20px 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const NavLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  gap: 8px;
  transition: color 0.3s ease-in-out, transform 0.3s;
  position: relative;
  padding: 5px 0;
  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #6ec1e4;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
  }
  &:hover, &:focus {
    color: #a1dbff;
    &::after {
      transform: scaleX(1);
    }
  }
  &:hover {
    transform: translateZ(15px);
  }
`;

const FooterText = styled.p`
  font-size: 0.9rem;
  text-align: center;
  animation: ${fadeIn} 0.5s ease-out;
  padding-top: 20px;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLogo>
        <EcoPart><LeafIcon />Keys</EcoPart>Ltd 
      </FooterLogo>
      <FooterInfo>
        <InfoItem>
          <InfoIcon><FiPhone /></InfoIcon>
          <InfoText>+92 317 580 7500</InfoText>
        </InfoItem>
        <InfoItem>
          <InfoIcon><FiMapPin /></InfoIcon>
          <InfoText>1163 Street 37, Block M 3 A Johar Town, Lahore</InfoText>
        </InfoItem>
        <InfoItem>
          <InfoIcon><FiMail /></InfoIcon>
          <InfoText>info@keys-limited.com</InfoText>
        </InfoItem>
      </FooterInfo>
      <FooterNav>
        <NavLink to="/"><FiHome /> Home</NavLink>
        <NavLink to="/about"><FiInfo /> About</NavLink>
        <NavLink to="/services"><FiBriefcase /> Portfolio</NavLink>
        <NavLink to="/contact"><FiMail /> Contact</NavLink>
      </FooterNav>
      <FooterText>© {new Date().getFullYear()} Keys Limited. All rights reserved.</FooterText>
    </FooterContainer>
  );
};

export default Footer;
