import React from 'react';
import styled, { keyframes } from 'styled-components';


const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const glowAnimation = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 8px rgba(34, 193, 195, 0.5);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(34, 193, 195, 0.75);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 8px rgba(34, 193, 195, 0.5);
  }
`;




const AboutContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 30px;
  background: #ffffff;
  animation: ${fadeInUp} 1s ease-out forwards;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #022c43;
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 15px;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60%;
    height: 4px;
    background-color: #22c1c3;
    border-radius: 2px;
    animation: ${glowAnimation} 2.5s infinite;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.8rem;
  color: #034f84;
  margin-bottom: 25px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -15px;
    left: 0;
    width: 25%;
    height: 6px;
    background: linear-gradient(to right, #22c1c3, #fdbb2d);
    border-radius: 15px;
  }
`;

const Content = styled.p`
  max-width: 900px;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.8;
  color: #336e7b;
  margin-bottom: 50px;
`;


const AboutUs = () => {
  return (
    <AboutContainer>
      <Title>Welcome to Keys Limited</Title>
      <Subtitle>Who We Are</Subtitle>
      <Content>
      Keys Limited - Your partner in digital success. We design, develop, and deliver custom business websites, portfolios, management systems, and e-commerce solutions that drive results. Let us help you achieve your online vision.
      </Content>

    </AboutContainer>
  );
};

export default AboutUs;
