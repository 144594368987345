import React from 'react';
import styled from 'styled-components';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import cunziteImage from "../assets/project1.png";
import bcwImage from "../assets/project2.png";
import anaqaImage from "../assets/project3.png";
import sweetdreamsImage from "../assets/sweetdreams_long.jpeg";
import wonderwomenImage from "../assets/wonder_women_long.jpeg";

import video from "../assets/Sequence.mp4";

// Styled Components
const HeroSection = styled.div`
  height: 60vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.5);

  video {
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%; 
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  h1, p {
    z-index: 1;
    font-size: 4rem;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
  }

  p {
    margin-top: 10px;
    font-size: 1.5rem;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 3rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`;

const ServicesContainer = styled.section`
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 30px;
  background: ${({ inverse }) => inverse ? '#f4f4f4' : 'white'};
  flex-direction: ${({ inverse }) => inverse ? 'row-reverse' : 'row'};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 40px 20px;
    text-align: center; /* Ensure text is centered on smaller screens */
  }
`;

const TextBlock = styled.div`
  flex: 1;
  padding: 20px;

  h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.3rem;
    color: #333;
    line-height: 1.8;
  }

  @media (max-width: 768px) {
    padding: 10px;
    p {
      font-size: 1.1rem;
    }
  }
`;

const ImageBlock = styled.div`
  flex: 1;
  padding: 20px;
  text-align: center;

  img {
    max-width: 80%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    img {
      max-width: 100%;
    }
  }
`;

const ServicesComponent = () => {
  const allProjects = [
    {
      href: "https://cunzite.com",
      img: cunziteImage,
      alt: "Cunzite",
      description: "The website Cunzite offers a variety of luxury perfumes and fragrance products, primarily catering to customers in the United Arab Emirates. It features collections of exclusive perfumes for both men and women, including natural oud, essential oils, and incense. The site also highlights bestsellers, special gift sets, and exclusive offers. Customers can shop online, explore various fragrance collections, and join the mailing list for updates on promotions and discounts."
    },
    {
      href: "https://bcw.club",
      img: bcwImage,
      alt: "BCW",
      description: `The website BCW Club offers online gaming experiences related to cryptocurrency, featuring a game called "Crypto Crash." Users can participate in this game to potentially win big rewards with cryptocurrency. The platform provides options for playing, betting, and engaging in other related activities.`
    },
    {
      href: "https://anaqa.ae",
      img: anaqaImage,
      alt: "Anaqa",
      description: "Anaqa.ae is an online platform based in the UAE specializing in custom-printed t-shirts and hoodies for men and women. The website offers personalized apparel, allowing customers to create unique designs that reflect their style. In addition to t-shirts and hoodies, Anaqa also sells various accessories, including leather wallets, bags, and bed sheets."
    },
    {
      href: "https://wonderwomen.ae",
      img: wonderwomenImage,
      alt: "project wonder women",
      description: "WonderWomen offers custom-designed jewelry to express individuality. Our meticulously crafted pieces blend precision and originality, reflecting your unique style. Explore timeless collections crafted from precious metals and gemstones for weddings, special events, or everyday wear. Enjoy personalized service and exceptional quality with our seamless online shopping experience. Elevate your style with WonderWomen: Where sophistication meets self-expression."
    },
    {
      href: "https://sweetdreams.ae",
      img: sweetdreamsImage,
      alt: "project sweetdreams",
      description: "Discover the epitome of luxury and comfort with our premium bedding sets, designed to transform your bedroom into a serene sanctuary. Each set is meticulously crafted from the finest quality fabrics, ensuring a soft, indulgent feel that invites restful sleep. Our timeless designs not only elevate your decor but also reflect a sophisticated sense of style that never goes out of fashion. Whether you’re looking to refresh your space or create a whole new ambiance, our bedding collections offer the perfect blend of elegance and comfort. Experience the luxury you deserve, all at an affordable price where comfort seamlessly meets sophistication."
    },
  ];

  return (
    <>
      <HeroSection>
        <video src={video} autoPlay muted loop />
        <h1>Our Portfolio</h1>
        <p>Keys Limited: Innovating Custom Web Projects. Explore our stunning websites, portfolios, and robust e-commerce solutions</p>
      </HeroSection>
      <ServicesContainer>
        { allProjects.map(({href, img, alt, description}, index) => {
          return (
            <Section inverse={index % 2}>
              <TextBlock>
                <h2>{alt}</h2>
                <p>
                  {description}
                </p>
              </TextBlock>
              <ImageBlock>
                <a href={href} target="_blank" rel="noopener noreferrer">
                  <img src={img} alt="Cunzite.com" />
                </a>
              </ImageBlock>
            </Section>
          );
        })}
      </ServicesContainer>
    </>
  );
};

export default ServicesComponent;
