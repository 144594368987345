import React from 'react';
import styled from 'styled-components';
import { MdExpandMore } from 'react-icons/md';
import anaqaImage from '../assets/anaqa.PNG';
import cunziteImage from '../assets/cunzite.PNG';
import wonderwomenImage from '../assets/wonderwoman.jpeg';
import bcwImage from '../assets/bcw.PNG';
import sweetdreamsImage from '../assets/sweet_dreams.jpeg'

const ProjectsContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 50px;
  background: #f5f5f5;
  border-top: 5px solid #007bff;
`;

const ProjectsTitle = styled.h1`
  font-size: 3.5rem;
  color: #123456;
  margin: 0 0 50px 0;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    height: 5px;
    background-color: #007bff;
    border-radius: 2px;
  }
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  width: 100%;
  max-width: 1400px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

const ProjectContent = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.95);
  height: 50%;
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
  border-radius: 0 0 15px 15px;
`;

const ProjectCard = styled.div`
  background: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
  height: 400px;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.2);
  }

  &:hover ${ProjectContent} {
    transform: translateY(0%);
    opacity: 1;
  }
`;

const ProjectImageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    transition: transform 0.5s ease-in-out;
  }

  &:hover img {
    transform: scale(1.15) rotate(-2deg);
  }
`;

const ProjectTitle = styled.h3`
  font-size: 1.8rem;
  color: #123456;
  margin: 0 0 15px;
  text-align: center;
`;

const ProjectDescription = styled.p`
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const MoreIcon = styled(MdExpandMore)`
  font-size: 2.5rem;
  color: #007bff;
  transition: color 0.3s, transform 0.3s;
  align-self: center;
  margin-top: 20px;
  ${ProjectCard}:hover & {
    color: #0056b3;
    transform: rotate(180deg);
  }
`;


const OurProjects = () => {
  const allProjects = [
    {
      href: "https://cunzite.com",
      img: cunziteImage,
      alt: "project cunzite",
      description: "The website Cunzite offers a variety of luxury perfumes and fragrance products."
    },
    {
      href: "https://bcw.club",
      img: bcwImage,
      alt: "project bcw",
      description: `The website BCW Club appears to be focused on offering online gaming experiences.`
    },
    {
      href: "https://anaqa.ae",
      img: anaqaImage,
      alt: "project anaqa",
      description: "Anaqa.ae is an online platform based in the UAE specializing in custom-printed t-shirts."
    },
    {
      href: "https://wonderwomen.ae",
      img: wonderwomenImage,
      alt: "project wonder women",
      description: "WonderWomen offers custom-designed jewelry to express individuality."
    },
    {
      href: "https://sweetdreams.ae",
      img: sweetdreamsImage,
      alt: "project sweetdreams",
      description: "Premium bedding sets for ultimate comfort and style. Elevate your decor with timeless designs."
    },
  ];

  return (
    <ProjectsContainer>
      <ProjectsTitle>Our Projects</ProjectsTitle>
      <ProjectsGrid>
        { allProjects.map(({href, img, alt, description}, index) => {
          return (
            <ProjectCard>
              <a href={href} target="_blank" rel="noopener noreferrer">
                <ProjectImageContainer>
                  <img src={img} alt={alt} />
                </ProjectImageContainer>
              </a>
              <ProjectContent>
                <ProjectTitle>Cunzite.com</ProjectTitle>
                <ProjectDescription>
                  {description}
                </ProjectDescription>
                <MoreIcon />
              </ProjectContent>
            </ProjectCard>
          );
        }) }
      </ProjectsGrid>
    </ProjectsContainer>
  );
};

export default OurProjects;
