import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaClock, FaCheckCircle, FaFacebook, FaTwitter, FaLinkedin ,FaQuestionCircle} from 'react-icons/fa';
import { FiSend } from 'react-icons/fi';
import bgImage from '../assets/slider1.jpg';

// Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

// Common Theme Colors
const primaryColor = '#285928';
const secondaryColor = '#1b3b1b';
const backgroundColor = '#f0f2f5';
const textColor = '#333';
const highlightColor = '#fff';

// Styled Components
const HeroSection = styled.div`
  height: 100vh;
  background-image: url(${bgImage});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${highlightColor};
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 1000px rgba(0, 0, 0, 0.5);
  animation: ${fadeIn} 1.5s ease-out;

  h1 {
    font-size: 4.5rem;
    background: rgba(0, 0, 0, 0.6);
    padding: 20px 40px;
    border-radius: 10px;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
      animation: ${pulse} 0.7s infinite;
    }
  }

  p {
    margin-top: 15px;
    font-size: 1.5rem;
    background: rgba(0, 0, 0, 0.6);
    padding: 10px 20px;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 3.5rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`;

const ContactWrapper = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 80px 60px;
  background: ${highlightColor};
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  animation: ${fadeIn} 1.5s ease-out;
  display: flex;
  gap: 40px;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const ContactForm = styled.div`
  padding: 40px;
  background: ${backgroundColor};
  border-radius: 15px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  flex: 1;
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 20px;
  }
`;

const ContactInfo = styled.div`
  padding: 40px;
  background: ${backgroundColor};
  border-radius: 15px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  flex: 1;

  h2 {
    margin-bottom: 20px;
  }
`;

const FormField = styled.div`
  margin-bottom: 25px;

  label {
    display: block;
    font-size: 1.3rem;
    margin-bottom: 10px;
    color: ${textColor};
  }

  input, textarea {
    width: 100%;
    padding: 12px;
    font-size: 1.1rem;
    border-radius: 12px;
    border: 1px solid #ccc;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;

    &:focus {
      border-color: ${primaryColor};
      box-shadow: 0 0 8px rgba(40, 89, 40, 0.2);
    }
  }

  textarea {
    min-height: 140px;
  }
`;

const SubmitButton = styled.button`
  background: linear-gradient(to right, ${primaryColor}, ${secondaryColor});
  color: ${highlightColor};
  font-size: 1.4rem;
  padding: 15px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease;

  &:hover {
    background: linear-gradient(to right, ${secondaryColor}, ${primaryColor});
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  }

  svg {
    font-size: 1.4rem;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 30px;

  a {
    font-size: 2rem;
    color: ${primaryColor};
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.2);
    }
  }
`;

const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  padding: 15px 20px;
  background: ${primaryColor};
  color: ${highlightColor};
  border-radius: 15px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);

  svg {
    font-size: 1.8rem;
  }

  span {
    font-size: 1.3rem;
  }
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
  font-family: 'Roboto', sans-serif;

  svg {
    font-size: 2rem;
    color: ${primaryColor};
  }

  div {
    h4 {
      font-size: 1.4rem;
      color: ${textColor};
    }

    p {
      font-size: 1.3rem;
      color: ${textColor};
    }
  }
`;

const MapEmbed = styled.iframe`
  width: 100%;
  height: 350px;
  border: none;
  border-radius: 15px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
`;
// FAQ Component
const FAQContainer = styled.div`
  padding: 40px 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  margin-top:20px;
`;

const FAQHeader = styled.h3`
  font-size: 2rem;
  text-align: center;
  color: #285928;
`;

const Question = styled.div`
  margin-top: 20px;
  cursor: pointer;
`;

const Answer = styled.p`
  margin-top: 10px;
  display: ${({ show }) => (show ? 'block' : 'none')};
  font-size: 1.2rem;
  color: #333;
`;




const FAQItem = ({ question, answer }) => {
    const [show, setShow] = useState(false);
    return (
      <Question onClick={() => setShow(!show)}>
        <h4>
          <FaQuestionCircle /> {question}
        </h4>
        <Answer show={show}>{answer}</Answer>
      </Question>
    );
  };
  
const ContactUs = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Replace with actual form submission logic
    setFormSubmitted(true);
  };

  return (
    <>
      <HeroSection>
        <h1>Contact Us</h1>
        <p>Let's Connect for Networking Solutions</p>
      </HeroSection>
      <ContactWrapper>
        <ContactForm>
          <h2>Get in Touch</h2>
          <form onSubmit={handleSubmit}>
            <FormField>
              <label htmlFor="name">Your Name:</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleInputChange} required />
            </FormField>
            <FormField>
              <label htmlFor="email">Your Email:</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} required />
            </FormField>
            <FormField>
              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" value={formData.message} onChange={handleInputChange} required />
            </FormField>
            <SubmitButton type="submit">
              <FiSend /> Send Message
            </SubmitButton>
            {formSubmitted && (
              <SuccessMessage>
                <FaCheckCircle />
                <span>Thank you! Your message has been sent successfully.</span>
              </SuccessMessage>
            )}
          </form>
        </ContactForm>
        <ContactInfo>
          <h2>Reach Out to Us</h2>
          <InfoBlock>
            <FaPhone />
            <div>
              <h4>Phone</h4>
              <p>+92 317 5807500</p>
            </div>
          </InfoBlock>
          <InfoBlock>
            <FaEnvelope />
            <div>
              <h4>Email</h4>
              <p>info@keys-limited.com</p>
            </div>
          </InfoBlock>
          <InfoBlock>
            <FaMapMarkerAlt />
            <div>
              <h4>Location</h4>
              <p>1163 Street 37, Block M 3 A Johar Town, Lahore</p>
            </div>
          </InfoBlock>
          <InfoBlock>
            <FaClock />
            <div>
              <h4>Office Hours</h4>
              <p>Mon-Fri: 9 PM - 6 PM</p>
            </div>
          </InfoBlock>
          <SocialMedia>
            <a href="https://www.facebook.com/keys-limited"><FaFacebook /></a>
            <a href="https://www.twitter.com/keys-limited"><FaTwitter /></a>
            <a href="https://www.linkedin.com/company/keys-limited"><FaLinkedin /></a>
          </SocialMedia>
        </ContactInfo>
      </ContactWrapper>
      <FAQContainer>
          <FAQHeader>Frequently Asked Questions</FAQHeader>
          <FAQItem question="What industries do you specialize in?" answer="We provide multiple services, including Web development, Android development, Cloud infrastructure management, Third part integrations." />
          <FAQItem question="How do you ensure data security?" answer="Our cybersecurity solutions include encryption, firewalls, and real-time threat monitoring to secure your data." />
          <FAQItem question="Can I customize a service package?" answer="Absolutely! We offer tailor-made packages to suit your specific business needs." />
        </FAQContainer>
      <MapEmbed
  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3022.0152133045863!2d-73.9653551!3d40.7828647!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2588fb21466a3%3A0xf3c6b13c14d7aefa!2sCentral%20Park!5e0!3m2!1sen!2sus!4v1582247743956"
  allowFullScreen
/>
    </>
  );
};

export default ContactUs;
